<template>
    <component
        :is="svgs[name] ?? svgs[key]"
        class="inline-block max-w-none"
        v-bind="$attrs"
    />
</template>

<script setup>
// Icon source is Google Material: https://fonts.google.com/icons
import * as svgs from "@/Components/Modules/SvgPaths";
import { computed } from "vue";
import _ from "lodash";

const props = defineProps(["name"]);

const key = computed(() => {
    let split = props.name?.split("-");

    if (props.name?.startsWith("icon-")) {
        split.shift();
    }

    return split.map((part) => _.capitalize(_.lowerCase(part))).join("");
});
</script>
