import { AnalyticsBrowser } from "@segment/analytics-next";
import {eventBus, useAssetTransforms, useChat, useDialog} from "@/Composables";
import _ from "lodash";
import {usePage} from "@inertiajs/vue3";

let initialized = false;
let settings = null;
export const analytics = new AnalyticsBrowser();

export default function useSegment() {
    if (!import.meta.env.SSR) {
        if (!initialized) {
            initialized = true;
            settings = DynamicSettings.analytics ?? {};


            if (settings?.segment?.integrations) {
                analytics.identify(
                    settings.segment.id,
                    {
                        ga_client_id: getGoogleAnalyticsID(),
                        ...settings.segment.user,
                    },
                    {
                        integrations: settings.segment.integrations,
                    }
                );

                if(settings.segment.user.customer_id){
                    analytics.group(settings.segment.user.customer_id, settings.segment.company);
                }
            }
            analytics.load({ writeKey: import.meta.env.VITE_SEGMENT_KEY })
                //After loading Intercom hide the default button and move the Intercom Container up as if the button was still present.
                // this provides room for the custom button to show up in its place.
                .then(() => {

                    //Intercom config and even listeners
                    // Reference: https://developers.intercom.com/installing-intercom/web/methods/#intercomupdate
                    window.Intercom('update', {"hide_default_launcher": true, "vertical_padding": 85})

                    // Reference: https://developers.intercom.com/installing-intercom/web/methods/#intercomonhide
                    window.Intercom('onHide', () => {
                        useChat().setIsClosed();
                        eventBus.emit("chat:onClose")
                    });

                    // Reference: https://developers.intercom.com/installing-intercom/web/methods/#intercomonshow
                    window.Intercom('onShow', () => {
                        useDialog().forceClose();
                        useChat().setIsOpen();
                        eventBus.emit("chat:onShow")
                    });

                    let open_chat = _.get(usePage().props, "open_chat", false);

                    if(open_chat){
                        useChat().openChat();
                    }

                });

        }
    }

    function getGoogleAnalyticsID() {
        var cookie = {};
        document.cookie.split(';').forEach(function(el) {
            var splitCookie = el.split('=');
            var key = splitCookie[0].trim();
            cookie[key] = splitCookie[1];
        });
        return cookie["_ga"]?.substring(6) ?? '';
    }

    function commonProductData({ card, pricing, availability }) {
        return {
            item_status: card?.filter_status,
            id: card?.search_uid,
            sku: card?.item_id,
            name: card?.display_sku,
            brand: card?.filter_manufacturer,
            category: _.get(card, "filter_category.0", ""),
            variant: _.get(card, "filter_sub_category.0", ""),
            finish: _.get(card, `facet_finish.0`, ""),
            url: window.location.href.split("?")[0],
            image_url: useAssetTransforms().productCardImage(card),
            currency: "usd",
            price: pricing?.price,
            price_numeric: parseFloat(pricing?.price ?? 0)?.toFixed(2),
            discount: parseFloat((card?.list_price - (pricing?.price ?? 0)) ?? 0)?.toFixed(2),
            //list_price: card?.list_price ?? 0,
            inventory: availability?.total,
        };
    }

    function productViewed({ card, pricing, availability }) {
        analytics.track("Product Viewed",{
            ga_client_id : getGoogleAnalyticsID(),
            ...commonProductData({ card, pricing, availability })
        });
    }

    function zeroInventoryProductViewed(sku) {
        analytics.track("Zero Inventory Product Viewed", {
            ga_client_id : getGoogleAnalyticsID(),
            sku: sku,
        });
    }

    //SEC-2455
    function productSearchClick(searchTerm) {
        analytics.track("Products Searched",{
            ga_client_id : getGoogleAnalyticsID(),
            query: searchTerm,
            category: 'Product Search Click',
            label: searchTerm
        });
    }

    function productAdded(payload) {
        analytics.track("Product Added", {
            ga_client_id : getGoogleAnalyticsID(),
            ...payload
        });
    }

    function priorityBuildAdded(payload) {
        analytics.track("Priority Build Added", {
            ga_client_id : getGoogleAnalyticsID(),
            priority_build_added: true,
            ...payload
        });
    }

    function cartViewed(cart) {
        let products = cart.items.map((item, key) => {
            return {
                position: key,
                quantity: item.pivot.quantity,
                ...commonProductData({
                    card: cart.item_cards[item.item_id],
                    pricing: cart.pricing[item.item_id],
                    availability: cart.item_availability[item.item_id],
                }),
            };
        });

        analytics.track("Cart Viewed", {
            ga_client_id : getGoogleAnalyticsID(),
            cart_id: cart.id,
            products: products,
        });
    }

    function checkoutStarted(event) {
        let cart = event.cart
        let products = cart.items.map((item, key) => {
            return {
                position: key,
                quantity: item.pivot.quantity,
                ...commonProductData({
                    card: cart.item_cards[item.item_id],
                    pricing: cart.pricing[item.item_id],
                    availability: cart.item_availability[item.item_id],
                }),
            };
        });

        analytics.track("Checkout Started", {
            ga_client_id : getGoogleAnalyticsID(),
            cart_id: cart.id,
            currency: 'USD',
            total: cart.total,
            products: products
        });
    }

    function orderCompleted(event) {
        let cart = event.cart
        let products = cart?.items?.map((item, key) => {
            return {
                quantity: item.pivot.quantity,
                ...commonProductData({
                    card: cart.item_cards[item.item_id],
                    pricing: cart.pricing[item.item_id],
                    availability: cart.item_availability[item.item_id],
                })
            };
        });

        analytics.track("Order Completed", {
            ga_client_id : getGoogleAnalyticsID(),
            checkout_id: cart?.meta?.transaction_id,
            orderId: cart.reference_id,
            currency: 'USD',
            total: cart.total,
            products: products
        });
    }

    function orderFailed(event) {
        let user = DynamicSettings.user ?? {};
        analytics.track("Order Failed", {
            ga_client_id : getGoogleAnalyticsID(),
            category: "Checkout",
            label: user.id,
            order_id: event.order_id,
        });
    }

    function paymentInfoAdded(event) {
        let cart = event.cart
        let products = cart.items.map((item, key) => {
            return {
                position: key,
                quantity: item.pivot.quantity,
                ...commonProductData({
                    card: cart.item_cards[item.item_id],
                    pricing: cart.pricing[item.item_id],
                    availability: cart.item_availability[item.item_id],
                }),
            };
        });

        analytics.track("Payment Info Entered", {
            ga_client_id : getGoogleAnalyticsID(),
            cart_id: cart.id,
            currency: 'usd',
            total: cart.total,
            payment_method: cart?.meta?.payment_method ?? '', //TODO: Do we have anything besides Credit Cards we want to track? ACH is used in Invoice Payments
            products: products
        });
    }

    function paymentDeclined(event) {
        let user = DynamicSettings.user ?? {};
        analytics.track("Payment Declined", {
            ga_client_id : getGoogleAnalyticsID(),
            category: "Checkout",
            label: user.id,
            order_id: event.transaction_id,
        });
    }

    function termSearched(term) {
        term = _.trim(term);
        if (term !== "") {
            analytics.track("Products Searched", {
                ga_client_id : getGoogleAnalyticsID(),
                category: "Search",
                label: term,
                query: term,
            });
        }
    }

    function suggestedSearchClicked(term) {
        term = _.trim(term);
        if (term !== "") {
            analytics.track("Suggested Search Clicked", {
                ga_client_id : getGoogleAnalyticsID(),
                category: "Search",
                label: term,
                value: term,
            });
        }
    }

    function registerEventListeners() {
        eventBus.on("page:viewed", (event) => analytics.page());
        eventBus.on("catalog:product-viewed", (event) => productViewed(event));
        eventBus.on("catalog:product-search-click", (event) => productSearchClick(event)); //SEC-2455
        eventBus.on("cart:product-added", (event) => productAdded(event));
        eventBus.on("cart:priority-build-added", (event) => priorityBuildAdded(event));
        eventBus.on("cart:viewed", (event) => cartViewed(event));
        eventBus.on("checkout:started", (event) => checkoutStarted(event));
        eventBus.on("checkout:order-complete", (event) => orderCompleted(event));
        eventBus.on("checkout:order-failed", (event) => orderFailed(event));
        eventBus.on("checkout:add-payment-info", (event) => paymentInfoAdded(event));
        eventBus.on("checkout:payment-declined", (event) => paymentDeclined(event));
        eventBus.on("search:term-searched", (event) => termSearched(event));
        eventBus.on("search:suggestion-clicked", (event) =>
            suggestedSearchClicked(event)
        );
    }

    return {
        analytics,
        registerEventListeners,
        track: {
            productViewed,
            zeroInventoryProductViewed,
            productAdded,
            productSearchClick, //SEC-2455
            priorityBuildAdded,
            cartViewed,
            orderCompleted,
            orderFailed,
            checkoutStarted,
            paymentInfoAdded,
            paymentDeclined,
            termSearched,
            suggestedSearchClicked,
        },
    };
}
