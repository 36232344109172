import { computed, inject, ref } from "vue";
import { useForm } from "@inertiajs/vue3";
import { eventBus, useDialog } from "@/Composables";
import _ from "lodash";
import axios from "axios";

let initialized = false;
let route = null;
const defaultSettings = {
    is_dark_mode: false,
    is_sourcing_mode: false,
    density: "default",
    is_side_navigation_panel_open: true,
};

const transitioning = ref(false);
const isLoadingNotifications = ref(false);
const notificationsArray = ref([]);
const user = ref(null);

export default function useUser() {
    if (!import.meta.env.SSR) {
        if (!initialized) {
            initialized = true;
            user.value = DynamicSettings.user;
            route ??= inject("route");
        }
    }

    //save the settings in the db
    let saveSettings = () => {
        user.value.settings = {
            ...defaultSettings,
            ...(user.value.settings ?? {}),
        };

        let form = useForm(user.value.settings);

        form.post(route("web.account.profile.settings.update"), {
            only: ["flash", "layout", "sub_layout"],
            onFinish: () => (transitioning.value = false),
        });
    };

    function toggleDarkMode() {
        user.value.settings.is_dark_mode = !user.value.settings?.is_dark_mode;
        if (user.value.settings.is_dark_mode) {
            if (!document.documentElement.classList.contains("dark")) {
                document.documentElement.classList.add("dark");
            }
        } else {
            if (document.documentElement.classList.contains("dark")) {
                document.documentElement.classList.remove("dark");
            }
        }

        saveSettings();

    }

    function setDensity(density) {
        document.documentElement.classList.remove("dense", "compact", "default");

        user.value.settings.density = density;

        if (user.value.settings.density === "dense") {
            document.documentElement.classList.add("dense");
        } else if (user.value.settings.density === "compact") {
            document.documentElement.classList.add("compact");
        } else {
            document.documentElement.classList.add("default");
        }

        saveSettings();
    }

    function toggleTableView(table) {
        let key = `is_${table}_grid`;
        user.value.settings[key] = !user.value.settings[key];
        saveSettings();
    }

    function toggleSourcingMode() {
        useDialog().forceClose();
        transitioning.value = true;
        _.delay(() => {

            let form = useForm(user.value.settings);
            form.is_sourcing_mode = !user.value.settings?.is_sourcing_mode;

            _.delay(() => {
                form.post(route("web.account.profile.settings.update"));
            }, 150);
        }, 150);
    }

    function toggleSideNavigationPanel() {

        if(typeof user.value.settings.is_side_navigation_panel_open === "undefined") {
            user.value.settings.is_side_navigation_panel_open = !defaultSettings.is_side_navigation_panel_open;
        } else {
            user.value.settings.is_side_navigation_panel_open = !user.value.settings?.is_side_navigation_panel_open;
        }

        if (user.value.settings.is_side_navigation_panel_open) {
            eventBus.emit("user:side-navigation-panel-toggled-open")
        } else {
            eventBus.emit("user:side-navigation-panel-toggled-closed")
        }

        saveSettings();
    }

    const loadNotifications = async () => {
        isLoadingNotifications.value = true;
        await axios.get(route('api.v1.public.notifications.index'),{params:{with:['notifications']}})
            .then((response) => {
                notificationsArray.value = response.data;
            })
            .finally(() => isLoadingNotifications.value = false);
        return notificationsArray.value;
    };

    const setNotificationAsRead = (notification) => {
        isLoadingNotifications.value = true;
        axios.post(route('api.v1.public.notifications.update', notification))
            .then(response => {
                console.log('response', response);
                let index = notificationsArray.value.indexOf(notification);
                notificationsArray.value.splice(index, 1)
            })
            .catch(error => console.log('Error:', error))
            .finally(() => isLoadingNotifications.value = false);
    }

    const markAllAsRead = () => {
        isLoadingNotifications.value = true;
        axios.post(route('api.v1.public.notifications.update_all'))
            .then(() => {
                notificationsArray.value = [];
            })
            .catch(error => console.log('Error:', error))
            .finally(() => isLoadingNotifications.value = false);
    }

    function registerEventListeners() {
        eventBus.on("user:toggle-dark-mode", (event) => toggleDarkMode());
        eventBus.on("user:toggle-sourcing-mode", (event) =>
            toggleSourcingMode()
        );
        eventBus.on("user:toggle-side-navigation-panel", (event) =>
            toggleSideNavigationPanel()
        );
        eventBus.on("user:toggle-table-view", (table) =>
            toggleTableView(table)
        );
        eventBus.on("user:set-density", (density) => setDensity(density));
    }

    const hasRole = (role, guard = "web") => {
        return _.get(user.value, "roles." + guard, []).includes(role);
    }

    const hasAnyRole = (roles, guard = "web") => {
        return _.get(user.value, "roles." + guard, []).some( role => roles.includes(role));
    }

    return {
        user,
        transitioning,
        settings: computed(() => {
            return {
                ...defaultSettings,
                ...(user.value.settings ? user.value.settings : {}),
            };
        }),
        toggleDarkMode,
        toggleSourcingMode,
        toggleSideNavigationPanel,
        setDensity,
        saveSettings,
        registerEventListeners,
        toggleTableView,
        hasRole,
        hasAnyRole,
        isEmployee() {
            return hasAnyRole(["Employee","Developer"]) || _.get(user.value,'is_employee');
        },
        hasPermission(permission, guard = "web") {
            return _.get(user.value, "permissions." + guard, []).includes(
                permission
            );
        },
        loadNotifications,
        setNotificationAsRead,
        markAllAsRead,
        notificationCount: computed(() => notificationsArray.value.length),
        isLoadingNotifications,
        notifications: notificationsArray,
    };
}
